<template>
  <v-menu max-width="100%" v-model="menu" top nudge-top="60" :close-on-click="true" :close-on-content-click="false">
    <template v-slot:activator="{on, attrs}">
      <v-text-field :rules="rules" v-on="on" v-bind="attrs" :label="label" v-model="textDate"
                    :value="getFormattedDate(date)"></v-text-field>
    </template>
    <v-card>
      <v-date-picker @change="updateDate" v-model="date"></v-date-picker>
      <v-card-actions>
        <v-btn text color="primary" @click="menu = false">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";
import dayjs from "dayjs";
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat);
export default {
  name: "DateField",
  mixins: [DateUtilMixin, ValidationRulesMixin],
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: "Date",
    },
    rules: {
      type: Array,
      default: () => { return [] },
    },
  },
  data() {
    return {
      menu: false,
      date: this.value,
      textDate: this.value ? this.getFormattedDate(this.value) : null,
    }
  },
  methods: {
    updateDate() {
      this.textDate = this.getFormattedDate(this.date);
      this.$emit("input", this.date);
    }
  },
  watch: {
    textDate(newValue, old) {
      console.log(newValue);
      var date = dayjs(newValue, "DD/MM/YYYY");
      if(date.isValid() && newValue !== old) {
        this.date = date.format("YYYY-MM-DD");
        this.updateDate();
      }
    }
  }
}
</script>

<style scoped>

</style>