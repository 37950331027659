<template>
  <v-menu bottom>
    <template v-slot:activator="{on, attrs}">
      <v-tooltip bottom>
        <template v-slot:activator="{on: on2, attrs:attrs2}">
          <v-btn icon color="primary" v-on="on" v-bind="attrs">
            <v-icon v-on="on2" v-bind="attrs2">mdi-swap-horizontal</v-icon>
          </v-btn>
        </template>
        <span>{{ tooltipText[reglement.status] }}</span>
      </v-tooltip>
    </template>
    <v-sheet>
      <v-btn v-if="reglement.status === null && saisie_type !== 'encaissement'"
             @click="updateReglement(reglement, 'waiting')" color="primary">Mettre en attente de paiement
      </v-btn>
      <v-btn v-if="reglement.status === null && saisie_type !== 'decaissement'"
             @click="updateReglement(reglement, 'waiting_payment')" color="primary">Mettre en attente d'encaissement
      </v-btn>
      <v-btn v-if="reglement.status === 'waiting'" @click="updateReglement(reglement, 'valid')" color="primary">Valider
        la facture
      </v-btn>
      <v-btn v-if="reglement.status === 'waiting_payment'" @click="updateReglement(reglement, 'paid')" color="primary">
        Marquer comme payé
      </v-btn>
      <v-btn v-if="reglement.status === 'valid'" @click="updateReglement(reglement, 'check')" color="primary">Mise en
        paiement
      </v-btn>
      <v-btn v-if="reglement.status === 'check'" @click="updateReglement(reglement, 'paid')" color="primary">Marquer
        comme payé
      </v-btn>

      <br>
    </v-sheet>

  </v-menu>
</template>
<script>
import {ReglementRepository} from "@/repository/reglement";

export default {
  name: 'SwitchReglementStatusMenu',
  props: {
    reglement: {},
    saisie_type: {
      type: String,
      default: "all",
    }
  },
  data() {
    return {
      tooltipText: {
        'waiting': "Valider la facture",
        'waiting_payment': "Marquer comme à valider",
        'valid': "Mise en paiement",
        "check": "Marquer comme payé",
      }
    };
  },
  methods: {
    async updateReglement(reglement, status) {
      let repository = new ReglementRepository();
      let payload = {id: reglement.id, status};
      if(status === "paid") {
        var today = new Date();
        payload.date_reglement = today.toISOString().slice(0, 10);
      }
      try {
        await repository.update(payload);
        this.$store.dispatch("annonce/annonceSuccess", "Règlement mis à jour !");

      } finally {
        this.$emit("reload");
      }

    },
  }
}
</script>