<template>
  <v-container>
    <h2 class="primary--text">Suivi des financements</h2>
    <v-row no-gutters justify="space-between">
      <v-checkbox v-model="search.all" label="Voir tout les financements"></v-checkbox>
      <v-btn @click="resetFilters" color="red" class="white--text"><v-icon>mdi-trash-can</v-icon>Effacer les filtres</v-btn>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="4">
        <v-card color="primary" class="ma-4 pa-3 text-center">
          <h2 class="white--text">Total demandé<br> {{ total_du_text }}</h2>
        </v-card>
      </v-col>
      <v-col v-if="search.all" cols="4">
        <v-card color="cyan" class="ma-4 pa-3 text-center">
          <h2 class="white--text">Total reçu<br> {{ total_recu_text }}</h2>
        </v-card>
      </v-col>
      <v-col v-if="search.all" cols="4">
        <v-card color="orange" class="ma-4 pa-3 text-center">
          <h2 class="white--text">Reste à percevoir <br> {{ reste_a_percevoir_text }}</h2>
        </v-card>
      </v-col>
    </v-row>
    <v-simple-table class="mt-4" fixed-header dense>
      <template>
        <thead>
        <tr>
          <th>Nom du bénéficiaire</th>
          <th>Enveloppe</th>
          <th>Provenance</th>
          <th>Numéro arrêté</th>
          <th>Numéro BPI</th>
          <th>Date de demande</th>
          <th>Date de règlement</th>
          <th>Montant</th>
          <th>Statut</th>
          <th>Actions</th>
        </tr>
        <tr></tr>
        <tr>
          <th><v-text-field dense label="Nom du bénéficiaire" v-model="search.name"></v-text-field></th>
          <th><v-select dense :items="types_encaissement" v-model="search.source"></v-select></th>
          <th><v-select dense :items="provenances" v-model="search.provenance"></v-select></th>
          <th><v-text-field dense label="Numéro d'arrêté" v-model="search.numero_arrete"></v-text-field></th>
          <th><v-text-field dense label="Numéro BPI" v-model="search.numero_bpi"></v-text-field></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="reglement in filtered_reglements" :key="reglement.id">
          <td><router-link class="text-decoration-none" :to="'/finances/' + reglement.dossier_id">{{ reglement.beneficiaire_name }}</router-link></td>
          <td>{{ reglement.saisie_source }}</td>
          <td>{{reglement.provenance}}</td>
          <td>{{ reglement.numero_arrete }}</td>
          <td>{{ reglement.numero_bpi }}</td>
          <td>
            <span v-if="reglement.date_demande">{{ getFormattedDate(reglement.date_demande) }}</span>
            <span v-else>Non défini</span>
          </td>
          <td>
            <span v-if="reglement.date_reglement">{{ getFormattedDate(reglement.date_reglement) }}</span>
            <span v-else>Non défini</span>
          </td>
          <td>{{reglement.montant}} €</td>
          <td><reglement-status-chip :reglement="reglement"></reglement-status-chip></td>
          <td>
            <switch-reglement-status-menu @reload="reloadReglements" saisie_type="encaissement" :reglement="reglement"></switch-reglement-status-menu>
            <AddFileReglement @reload="reloadReglements" :reglement="reglement" saisie_type="encaissement"/>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import {ReglementRepository} from "@/repository/reglement";
import ReglementStatusChip from "@/components/finances/ReglementStatusChip.vue";
import {PROVENANCES, TYPES_ENCAISSEMENT} from "@/constants/Financement";
import {EntrepriseRepository} from "@/repository/entreprises";
import SwitchReglementStatusMenu from "@/views/SwitchReglementStatusMenu.vue";
import AddFileReglement from "@/views/AddFileReglement.vue";
import * as config from '@/config.json';
import {DateUtilMixin} from "@/mixins/DateUtilMixin";

const formatter = new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
      });

export default {
  name: "SuiviFinancements",
  components: {AddFileReglement, SwitchReglementStatusMenu, ReglementStatusChip},
  mixins: [DateUtilMixin],
  async mounted() {
    let repository = new ReglementRepository();
    this.reglements = await repository.fetchFinancements();
    let entrepriseRepo = new EntrepriseRepository();
    this.entreprises = await entrepriseRepo.fetch();
  },
  data() {
    return {
      reglements: [],
      search: {
        name: null,
        source: "Tout",
        provenance: "Tout",
        all: false,
      },
      types_encaissement: ["Tout", ...TYPES_ENCAISSEMENT],
      provenances: ["Tout", ...PROVENANCES],
      entreprises: [],
      justificatifSelect: [
        {name: "Tous", value: null},
        {name: "Avec justificatif", value: "avec"},
        {name: "Sans justificatif", value: "sans"},
      ],
      baseUrl: config.BASE_URL,
    }
  },
  computed: {
    filtered_reglements() {
      return this.reglements
          .filter((reglement) => this.search.name ? reglement.beneficiaire_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.search.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) : true)
          .filter((reglement) => this.search.numero_arrete ? reglement.numero_arrete.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.search.numero_arrete.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) : true)
          .filter((reglement) => this.search.numero_bpi ? reglement.numero_bpi.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.search.numero_bpi.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) : true)
          .filter((reglement) => this.search.source !== "Tout" ? reglement.saisie_source === this.search.source : true)
          .filter((reglement) => this.search.provenance !== "Tout" ? reglement.provenance === this.search.provenance : true)
          .filter((reglement) => this.search.justificatif !== null ? this.search.justificatif === "avec" ? reglement.file !== null : reglement.file === null : true)
          .filter((reglement) => !this.search.all ? reglement.status === "waiting_payment" || reglement.status === "valid" : true);
    },
    total_du() {
      let total = 0;
      for(let reglement of this.filtered_reglements) {
        if (reglement.montant !== null) {
          total += parseFloat(reglement.montant);
        }
      }
      return total;
    },
    total_du_text() {
      return formatter.format(this.total_du);
    },
    total_recu() {
      let total = 0;
      for(let reglement of this.filtered_reglements) {
        if (reglement.montant !== null && reglement.status === "paid") {
          total += parseFloat(reglement.montant);
        }
      }
      return total;
    },
    total_recu_text() {
      return formatter.format(this.total_recu);
    },
    reste_a_percevoir() {
      return this.total_du - this.total_recu;
    },
    reste_a_percevoir_text() {
      return formatter.format(this.reste_a_percevoir);
    }
  },
  methods: {
    async reloadReglements() {
      let repository = new ReglementRepository();
      this.reglements = await repository.fetchFinancements();
    },
    resetFilters() {
      this.search = {
        name: null,
        source: "Tout",
        entreprise: null,
        justificatif: null,
        all: false,
      };
    }
  }

}
</script>

