<template>
  <div>
    <v-chip v-if="reglement.status === 'paid'" color="primary">Payé</v-chip>
    <v-chip v-if="reglement.status === 'waiting'" class="white--text" color="orange">En atttente de paiement</v-chip>
    <v-chip v-if="reglement.status === 'waiting_payment'" class="white--text" color="orange">En attente d'encaissement
    </v-chip>
    <v-chip v-if="reglement.status === 'valid'"  class="white--text lighten-2" color="green">Validé</v-chip>
    <v-chip v-if="reglement.status === 'check'" class="white--text lighten-2" color="green">A vérifier</v-chip>

    <v-chip v-if="reglement.status === null" color="grey" class="darken-3 white--text">Inconnu
    </v-chip>
  </div>
</template>
<script>
export default {
  name: 'ReglementStatusChip',
  props: {
    reglement: {}
  }
}
</script>