<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-tooltip bottom>
        <template v-slot:activator="{on:on2, attrs: attrs2}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon v-on="on2" v-bind="attrs2">mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Modifier</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">
          Ajouter le justificatif
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="justificatifForm">
          <date-field v-if="saisie_type === 'encaissement'" v-model="demandeDate" label="Date de demande"></date-field>
          <date-field v-model="reglementDate" label="Date de règlement"></date-field>
          <date-field :rules="[notNullNorEmpty]" v-if="saisie_type === 'decaissement'" v-model="dateFacture" label="Date de facture"></date-field>
          <v-text-field :rules="[notNullNorEmpty]" v-if="saisie_type === 'decaissement'" v-model="referenceFacture"
                        label="Référence de la facture"></v-text-field>
          <v-file-input :rules="[notNullNorEmpty]" v-model="reglementFile" label="Justificatif"></v-file-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :disabled="updating" @click="updateReglement">Mettre à jour</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {ReglementRepository} from "@/repository/reglement";
import DateField from "@/components/base/DateField.vue";
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";

export default {
  name: 'AddFileReglement',
  components: {DateField},
  mixins: [ValidationRulesMixin],
  props: {
    reglement: {},
    saisie_type: {
      type: String,
      default: "all",
    }
  },
  data() {
    return {
      reglementDate: this.reglement.date_reglement,
      demandeDate: this.reglement.date_demande,
      dateFacture: this.reglement.date_facture,
      referenceFacture: this.reglement.reference_facture,
      reglementFile: null,
      dialog: false,
      updating: false,
    }
  },
  methods: {
    async updateReglement() {
      if (this.$refs.justificatifForm.validate()) {
        try {
          this.updating = true;
          let repo = new ReglementRepository();
          let data = {
            id: this.reglement.id,
          }
          if (this.reglementDate) {
            data.date_reglement = this.reglementDate;
          }
          if (this.demandeDate) {
            data.date_demande = this.demandeDate;
          }
          if (this.reglementFile) {
            data.file = this.reglementFile;
          }
          if (this.dateFacture) {
            data.date_facture = this.dateFacture;
          }
          if (this.referenceFacture) {
            data.reference_facture = this.referenceFacture;
          }
          let result = await repo.updateWithFile(data);
          if (result) {
            await this.$store.dispatch("annonce/annonceSuccess", "Règlement mis à jour");
          }
        } finally {
          this.dialog = false;
          this.updating = false;
          this.$emit("reload");
        }
      }

    }
  }
}
</script>
